import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import ClientsC from "@/components/Clients";

const ClientsP = () => {
  return (
    <Layout>
      <SEO title="Clients" canonical={`${useLocation().host}`} />
      <Breadcrumb
        name="Clients"
        pathName="Clients"
        imgUrl="/images/fotter-sub.jpeg"
      />
      <ClientsC />
    </Layout>
  );
};

export default ClientsP;
