import React from "react";

const clients = [
  {
    id: 1,
    img: "United Airlines.webp",
    url: "https://www.united.com/",
  },
  {
    id: 2,
    img: "TUIFLY GMBH.webp",
  },
  {
    id: 3,
    img: "Amerijet-International-Airlnes.webp",
    url: "https://www.amerijet.com/",
  },
  {
    id: 4,
    img: "TUIFLY NETHERLANDS.webp",
    url: "https://www.tui.nl/",
  },
  {
    id: 5,
    img: "zulu-global-logo-1-300x73.webp",
    url: "https://zulu.global/",
  },
  {
    id: 6,
    img: "SURINAME AIRWAYS.webp",
    url: "https://www.flyslm.com/",
  },
  {
    id: 7,
    img: "TEC4JETS.webp",
  },
  {
    id: 8,
    img: "TUI Airways LTD (UK).webp",
    url: "https://www.tui.co.uk/",
  },
  {
    id: 9,
    img: "TAP Air Portugal.webp",
    url: "https://www.flytap.com/",
  },
  {
    id: 10,
    img: "PSA_Airlines_Logo.webp",
    url: "https://psaairlines.com/",
  },
  {
    id: 11,
    img: "TAM.webp",
  },
  {
    id: 12,
    img: "TUI AIRLINES Belgium.webp",
  },
  {
    id: 13,
    img: "Pegasus Airlines.webp",
    url: "https://www.flypgs.com/",
  },
  {
    id: 14,
    img: "Sunwing Airlines.webp",
    url: "https://www.sunwing.ca/",
  },
  {
    id: 15,
    img: "TACA International.webp",
  },
  {
    id: 16,
    img: "South African Airlines.webp",
    url: "https://www.flysaa.com/",
  },
  {
    id: 17,
    img: "HiFly.webp",
    url: "https://hifly.aero/",
  },
  {
    id: 18,
    img: "Qantas.webp",
    url: "https://www.qantas.com/us/en.html",
  },
  {
    id: 19,
    img: "Omni Air.webp",
    url: "https://www.oai.aero/",
  },
  {
    id: 20,
    img: "Onur Air.webp",
  },
  {
    id: 21,
    img: "AIR ATLANTA ICELANDIC.webp",
    url: "https://www.airatlanta.com/",
  },
  {
    id: 22,
    img: "LATAM AIRLINES GROUP.webp",
    url: "https://www.latamairlines.com/",
  },
  {
    id: 23,
    img: "Free Bird Airlines.webp",
    url: "https://www.freebirdairlines.com/",
  },
  {
    id: 24,
    img: "Miami Air International.webp",
    url: "http://www.miamiair.com/",
  },
  {
    id: 25,
    img: "Interjet.webp",
    url: "https://interjet.com.mx/",
  },
  {
    id: 26,
    img: "FlyDubai.webp",
    url: "https://www.flydubai.com/",
  },
  {
    id: 27,
    img: "Lion Aerospace.webp",
    url: "https://lionaerospace.com/",
  },
  {
    id: 28,
    img: "FL Technics Jets.webp",
    url: "https://fltechnics.com/",
  },
  {
    id: 29,
    img: "El Al Israel Airlines.webp",
    url: "https://www.elal.com/es/Spain/Pages/default.aspx",
  },
  {
    id: 30,
    img: "ENTER AIR.webp",
    url: "https://www.enterair.pl/",
  },
  {
    id: 31,
    img: "Hawaiian Airlines.webp",
    url: "https://www.hawaiianairlines.com/",
  },
  {
    id: 32,
    img: "Eastern Airlines.webp",
    url: "https://iflyea.com/",
  },
  {
    id: 33,
    img: "Corendon Airlines.webp",
    url: "https://www.corendonairlines.com/",
  },
  {
    id: 34,
    img: "Blue Panorama Airlines.webp",
  },
  {
    id: 35,
    img: "AIR DO Airlines.webp",
    url: "https://www.airdo.jp/",
  },
  {
    id: 36,
    img: "Caribbean Airlines.webp",
    url: "https://www.caribbean-airlines.com/",
  },
  {
    id: 37,
    img: "Airfi.webp",
    url: "https://www.airfi.aero/",
  },
];

const Top = () => {
  return (
    <div className="rts-clients-area mt--60 mb--90">
      <div className="container">
        <div className="row logos-wrapper">
          {clients.map(({ id, img, url }) => (
            <div
              key={id}
              className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex img-wp"
            >
              <a href={url} target="_blank" rel="noreferrer">
                <img src={`/images/client/${img}`} alt="Client Logo" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Top;
