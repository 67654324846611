import React from "react";
import Top from "@/components/Clients/Top";
import Partners from "@/components/Home/Partners";

const ClientsC = () => {
  return (
    <>
      <Top />
      <Partners />
    </>
  );
};

export default ClientsC;
